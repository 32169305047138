import React from "react";
import { Paper, Typography } from "@mui/material";
import { X } from "lucide-react";
import DeviceStatus from "./device-status/DeviceStatus";

function HorizontalModal({ onClose }) {
  return (
    <div className="fixed top-0 w-full h-full flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-75 absolute top-0 left-0 w-full h-full"></div>
      <div className="w-[60%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Paper
          elevation={1}
          className="p-6 max-h-[700px] overflow-y-auto relative"
        >
          <button
            className="absolute top-3 right-3 focus:outline-none"
            onClick={onClose}
          >
            <X size={24} />
          </button>
          <Typography
            variant="h5"
            className="mb-4 text-center"
            style={{ fontFamily: "Futura, sans-serif" }}
          >
            My Device(s) Status
          </Typography>
          <br></br>
          <DeviceStatus />
        </Paper>
      </div>
    </div>
  );
}

export default HorizontalModal;
