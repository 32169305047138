// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pieChartBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 1400px) {
  .pieChartBox h1 {
    font-size: 24px;
  }
}
.pieChartBox .chart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.pieChartBox .options {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-size: 14px;
}
.pieChartBox .options .option {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.pieChartBox .options .option .title {
  display: flex;
  gap: 10px;
  align-items: center;
}
.pieChartBox .options .option .title .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/components/pieCartBox/pieChartBox.scss","webpack://./src/dashboard/styles/responsive.scss"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AAFF;ACsBE;EDlBA;IAEI,eAAA;EAFJ;AACF;AAKE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AAHJ;AAME;EACE,aAAA;EACA,8BAAA;EACA,SAAA;EACA,eAAA;AAJJ;AAMI;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AAJN;AAMM;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AAJR;AAMQ;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAJV","sourcesContent":["@import \"../../styles/responsive.scss\";\n\n\n.pieChartBox {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n\n  h1{\n    @include xxl{\n      font-size: 24px;\n    }\n  }\n\n  .chart {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    height: 100%;\n  }\n\n  .options {\n    display: flex;\n    justify-content: space-between;\n    gap: 10px;\n    font-size: 14px;\n\n    .option {\n      display: flex;\n      flex-direction: column;\n      gap: 10px;\n      align-items: center;\n\n      .title {\n        display: flex;\n        gap: 10px;\n        align-items: center;\n\n        .dot {\n          width: 10px;\n          height: 10px;\n          border-radius: 50%;\n        }\n      }\n    }\n  }\n}\n","$sm: 480px;\n$md: 768px;\n$lg: 1024px;\n$xl: 1200px;\n$xxl: 1400px;\n\n@mixin sm {\n  @media screen and (max-width: ($sm)) {\n    @content;\n  }\n}\n@mixin md {\n  @media screen and (max-width: ($md)) {\n    @content;\n  }\n}\n@mixin lg {\n  @media screen and (max-width: ($lg)) {\n    @content;\n  }\n}\n@mixin xl {\n  @media screen and (max-width: ($xl)) {\n    @content;\n  }\n}\n@mixin xxl {\n  @media screen and (max-width: ($xxl)) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
