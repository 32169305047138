// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.barChartBox {
  width: 100%;
  height: 100%;
}
.barChartBox h1 {
  font-size: 20px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/components/barChartBox/barChartBox.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AACE;EACE,eAAA;EACA,mBAAA;AACJ","sourcesContent":[".barChartBox{\n  width: 100%;\n  height: 100%;\n\n  h1{\n    font-size: 20px;\n    margin-bottom: 20px;\n  }\n\n  .chart{\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
