// Reusable loading indicator component
const LoadingComponent = () => {
    return (
      <div data-testid="loading-component" className="border border-green-300 m-2 shadow rounded-md p-4 max-w-sm w-full mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div data-testid="loading-element-1" className="rounded-full bg-slate-700 h-10 w-10"></div>
          <div data-testid="loading-element-2" className="flex-1 space-y-6 py-1">
            <div data-testid="loading-element-3" className="h-2 bg-slate-700 rounded"></div>
            <div data-testid="loading-element-4" className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                <div className="h-2 bg-slate-700 rounded col-span-1"></div>
              </div>
              <div data-testid="loading-element-5" className="h-2 bg-slate-700 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default LoadingComponent;