import React from 'react'

export default function AppHeader({title}) {
  return (
    <div
      className="text-white flex items-center p-2 justify-between"
      style={{ backgroundColor: "#52b44b" }}
    >
      <div className="h-10 w-10">
        <img src="/images/chat.png" alt="VA" />
      </div>

      <p data-testid="chat-assistant" className="font-futura-bold text-lg bold">
        {title}
      </p>
      <div className="p-2"></div>
    </div>
  );
}
