import React, { useState } from "react";
import { postFeedback } from "../api/api";
import { toast } from "react-toastify";
import styled from "styled-components";

// Styled components for the form
const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogContainer = styled.div`
  background: #fff;
  padding: 24px;
  width: 50%;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

const DialogTitle = styled.h2`
  margin: 0 0 16px 0;
  font-size: 24px;
`;

const DialogContent = styled.div`
  margin-bottom: 16px;
`;

const DialogActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const InputField = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const TextAreaField = styled.textarea`
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 8px 16px;
  margin-left: 8px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.primary ? "#007BFF" : "#ccc")};
  color: ${(props) => (props.primary ? "#fff" : "#000")};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0056b3" : "#bbb")};
  }
`;

const ErrorText = styled.p`
  color: red;
  margin: 8px 0;
`;

// Main component
const FeedbackModal = ({ open, onClose }) => {
  const [text, setText] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!text) {
      setError("Please fill in the feedback.");
      return;
    }

    try {
      await postFeedback({ text, file });
      toast.success("Feedback sent successfully");
      setText("");
      setFile(null);
      setError(null);
      onClose();
    } catch (err) {
      toast.error("Failed to send feedback. Please try again.");
    }
  };

  if (!open) return null; // Only render the dialog if open is true

  return (
    <DialogOverlay>
      <DialogContainer>
        <DialogTitle>Submit Feedback</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            {error && <ErrorText>{error}</ErrorText>}
            <TextAreaField
              placeholder="Share with us feedback or ideas so that we can improve."
              rows="4"
              value={text}
              onChange={(e) => setText(e.target.value)}
              required
            />
            {/* <InputField
              type="file"
              // onChange={(e) => {
              //   if (e.target.files.length > 0) {
              //     setFile(e.target.files[0].name);
              //   }
              // }}
            /> */}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onClose();
                setError(null);
              }}
            >
              Cancel
            </Button>
            <Button primary type="submit">
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogContainer>
    </DialogOverlay>
  );
};

export default FeedbackModal;
