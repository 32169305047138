// HomePage.js
import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";

const HomePage = () => {
  return (
    <div className="flex">
      <Sidebar />
      <div
        className="container max-w-screen-2xl"
        style={{
          maxHeight: "100vh",
          background: "#f4f4f9",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default HomePage;
