import React, { createContext, useContext, useState } from "react";

export const SidebarContext = createContext();

export const useSideBarContext = () => useContext(SidebarContext);

export const SidebarContextProvider = ({ children }) => {
  const [expanded, setExpanded] = useState(true);
  const [darkMode, setDarkMode] = useState(false);

  const toggleExpanded = () => {
    setExpanded((prevState) => !prevState);
  };

  const toggleDarkMode = () => {
    setDarkMode((prevState) => !prevState);
  };

  return (
    <SidebarContext.Provider
      value={{ expanded, darkMode, toggleExpanded, toggleDarkMode }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
