import React, { useState } from "react";
import styled from "styled-components";
import { ArrowRight } from "lucide-react";

// Static data for popular links
const applications = [
  {
    imageUrl: "/images/chat.png",
    name: "AbbyBot",
    description: "Account unlock | Password reset",
    link: "https://abbybot.safaricom.co.ke/",
  },
  {
    imageUrl: "/images/Calandar.png",
    name: "Room Booking",
    description: "Book A Meeting Room",
    link: "https://apps.powerapps.com/play/e/default-19a4db07-607d-475f-a518-0e3b699ac7d0/a/23064298-faf8-4882-8084-185efa4c7d7f?tenantId=19a4db07-607d-475f-a518-0e3b699ac7d0&source=teamsopenwebsite&hint=76abbc24-249f-4c19-9526-008e2573a56c&sourcetime=1694449105114",
  },
  {
    imageUrl: "/images/sccm-apps.png",
    name: "Application Catalogue",
    description: "Quickly download softwares",
    link: Function,
  },
  {
    imageUrl: "/images/favicon_48x48.ico",
    name: "Remedy",
    link: "https://remedy.safaricom.co.ke:8443/arsys/forms/u.remedy9.safaricom.net/SHR%3ALandingConsole/Default+Administrator+View/?cacheid=20f53de6",
  },
  {
    imageUrl: "/images/sharepoint.png",
    name: "Nerve Center",
    link: "https://safaricomo365.sharepoint.com/sites/safaricom/SitePages/Nerve-Cen.aspx",
  },
  {
    imageUrl:
      "https://cdn.icon-icons.com/icons2/2699/PNG/512/atlassian_jira_logo_icon_170511.png",
    name: "Safaricom Jira",
    link: "https://safaricom.atlassian.net/",
  },
  {
    imageUrl: "/images/shub.jpg",
    name: "S-Hub",
    link: "https://erpprod.safaricom.net/OA_HTML/OA.jsp?OAFunc=OAHOMEPAGE",
  },
  {
    imageUrl: "",
    name: "Wezesha",
    link: "https://egjd.fa.us6.oraclecloud.com/fscmUI/faces/FuseWelcome?_adf.no-new-window-redirect=true&_adf.ctrl-state=14dp9dto5l_1&_adf.no-new-window-redirect=true&_afrLoop=22411354788888045&_afrWindowMode=2&_afrWindowId=null&_afrFS=16&_afrMT=screen&_afrMFW=1232&_afrMFH=598&_afrMFDW=1280&_afrMFDH=720&_afrMFC=8&_afrMFCI=0&_afrMFM=0&_afrMFR=144&_afrMFG=0&_afrMFS=0&_afrMFO=0",
  },
  {
    name: "GitLab",
    imageUrl:
      "https://about.gitlab.com/images/press/logo/png/gitlab-icon-rgb.png",
    link: "https://gitlab.com",
  },
];

// Styled Components
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const ModalBox = styled.div`
  width: 100%;
  max-width: 800px;
  max-height: 100vh;
  overflow: auto;
`;

const Title = styled.h4`
  margin-bottom: 24px;
  text-align: center;
  font-family: "Futura, sans-serif";
`;

const SearchField = styled.input`
  width: 100%;
  margin-bottom: 24px;
  padding: 12px;
  border-radius: 9999px;
  border: 1px solid #ddd;
  font-family: "Futura, sans-serif";
`;

const CardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: 16px;
  width: 224px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 16px;
  border-radius: 50%;
`;

const CardTitle = styled.h6`
  margin-bottom: 8px;
  text-align: center;
  font-family: "Futura, sans-serif";
`;

const CardDescription = styled.p`
  margin-bottom: 16px;
  text-align: center;
  font-family: "Futura, sans-serif";
`;

const ViewButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  background: #52b44b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Futura, sans-serif";
  font-size: 16px;

  &:hover {
    background: #3d8b37;
  }
`;

const Modal = ({ closeModal }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Container>
      <ModalBox>
        <Title>Product Catalog</Title>
        <SearchField
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
        />
        <CardGrid>
          {applications
            .filter((app) =>
              app.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((app, index) => (
              <Card key={index}>
                <Avatar src={app.imageUrl} alt={app.name} />
                <CardTitle>{app.name}</CardTitle>
                <CardDescription>{app.description}</CardDescription>
                <ViewButton
                  onClick={() => {
                    app.name === "Application Catalogue"
                      ? (window.location.href = "softwarecenter: SoftwareID")
                      : window.open(app.link, "_blank");
                  }}
                >
                  View <ArrowRight size={15} style={{ marginLeft: "8px" }} />
                </ViewButton>
              </Card>
            ))}
        </CardGrid>
      </ModalBox>
    </Container>
  );
};

export default Modal;