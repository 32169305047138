import "./navbar.scss";

const Navbar = () => {
  return (
    <div className="navbar" style={{ borderBottom: "1px solid black" }}>
      <div className="logo">
        <img src="/images/Safaricom-Logo.wine.svg" width={300} alt="" />
        <span>Arifa Dashboard</span>
      </div>
    </div>
  );
};

export default Navbar;
