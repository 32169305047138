import React from "react";
import styled from "styled-components";

// Styled Components
const InfoContainer = styled.div`
  background-color: #ebf8ff;
  border-top: 4px solid #4299e1;
  border-radius: 0 0 4px 4px;
  color: #2d3748;
  padding: 16px;
  margin: 8px 0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

const InfoContent = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  padding: 4px;
`;

const Icon = styled.svg`
  fill: currentColor;
  height: 24px;
  width: 24px;
  color: #4299e1;
  margin-right: 16px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  font-family: "Futura, sans-serif";
  font-size: 14px;
  font-weight: bold;
`;

const HighlightText = styled.span`
  color: #48bb78;
`;

export default function Info() {
  return (
    <InfoContainer role="alert">
      <InfoContent>
        <IconWrapper>
          <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
          </Icon>
        </IconWrapper>
        <TextWrapper>
          For laptops due replacement please contact&nbsp;
          <HighlightText>
            enduserdeviceadministrator@safaricom.co.ke
          </HighlightText>
        </TextWrapper>
      </InfoContent>
    </InfoContainer>
  );
}
