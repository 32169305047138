import { createContext, useContext, useReducer, useState } from 'react';
import authReducer, { initialState } from '../reducers/authReducer';

const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    const [username, setUsername] = useState('');
    const [usernameErr, setUsernameErr] = useState('');
    const [showOtp, setShowOtp] = useState(false);
    const [otpValues, setOtpValues] = useState(['','','','','','']);
    const [otpErr, setOtpErr] = useState('');

    const setUsernameValue = (username) => setUsername(username);
    const setUsernameErrMsg = (errMsg) => setUsernameErr(errMsg);
    const setShowOtpForm = (showOtp) => setShowOtp(showOtp);
    const setOtpValue = (otpValues) => setOtpValues(otpValues);
    const setOtpErrMsg = (otpErr) => setOtpErr(otpErr);

    // const authenticateOtp = async (otp) => {
    //     dispatch({ type: 'AUTH_REQUEST' });
    //     try {
    //       const accessToken = await generateAccessToken();
    //       const { phoneNumber } = await getUserDetails(username);
    //       const otpData = await sendOTP(phoneNumber, accessToken);

    //       if(otp !== otpData){
    //         dispatch({ type: 'AUTH_FAILURE', payload: "Invalid OTP" });
    //         return;
    //       }

    //       dispatch({ type: 'AUTH_OTP_SUCCESS', payload: state.user });
    //       return state.user;
    //     } catch (error) {
    //         dispatch({ type: 'AUTH_FAILURE', payload: error.message });
    //         setOtpErr(error.message);
    //     }
    // }

    const value = {
      state, dispatch, username, setUsernameValue,
      usernameErr, setUsernameErrMsg,
      showOtp, setShowOtpForm, otpValues, setOtpValue,
      otpErr, setOtpErrMsg
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}