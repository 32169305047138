import { createContext, useState, useContext, useEffect } from 'react';

export const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({children}) => {

    const [appState, setState] = useState(() => {
      const cookieValue = document.cookie
      .split('; ')
      .find((cookie) => cookie.startsWith('user'))
      ?.split('=')[1];

      return cookieValue ? JSON.parse(decodeURIComponent(cookieValue)) : {};
    });
  
    const setLocalStorage = (name, value) => {
      localStorage.setItem(name, JSON.stringify(value));
    }

    const setCookie = (name, value, minutes) => {
      const expires = new Date();
      expires.setTime(expires.getTime() + minutes * 60 * 1000);
      document.cookie = `${name}=${JSON.stringify(value)};expires=${expires.toUTCString()};path=/`;
      setLocalStorage(name, value)
    };

    const updateState = newState => {
        setState(newState);
        setCookie('user', newState, 720);
    };

    const unsetCookie = () => {
      document.cookie = `user=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
      localStorage.removeItem("user")
    };

    useEffect(() => {
      const checkCookieExpiry = () => {
          const cookieValue = document.cookie
              .split('; ')
              .find((cookie) => cookie.startsWith('user'))
              ?.split('=')[1];
          if (!cookieValue) {
            setState({});
            localStorage.setItem("user", JSON.stringify({}))
          }
      };

      checkCookieExpiry();

      const intervalId = setInterval(checkCookieExpiry, 60000);

      return () => clearInterval(intervalId);
    }, []);
    
    return (
      <AppContext.Provider value={{ appState, updateState, unsetCookie }}>
        {children}
      </AppContext.Provider>
    );
}