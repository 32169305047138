import { useAppContext } from "../contexts/AppContext";

function Greeting() {
  const { appState } = useAppContext();
  const currentHour = new Date().getHours();

  let greeting;
  if (currentHour >= 0 && currentHour < 12) {
    greeting = "Good Morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }
  return (
    <>
      <div
        style={{
          "background-image": "linear-gradient(to right, #52b44b, red)",
          "-webkit-background-clip": "text",
          "background-clip": "text",
          fontSize: "2.7rem",
          color: "transparent",
        }}
        data-testid="greeting"
        className="font-futura-bold text-4xl font-bold p-2"
      >
        <p>{`${greeting}, ${appState.firstName}`}</p>
      </div>
      <p
        className="font-futura text-5xl text-gray-400 font-light p-2"
        style={{ color: "#c4c7c5", fontSize: "3.1rem" }}
      >
        How can I help you today?
      </p>
    </>
  );
}

export default Greeting;
