export const initialState = {
    status: [],
    loading: false,
    error: null,
  };

  const deviceStatusReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_DEVICE_STATUS_REQUEST':
          return { ...state, loading: true, error: null };
    
        case 'FETCH_DEVICE_STATUS_SUCCESS':
          return { ...state, status: action.payload, loading: false, error: null };
    
        case 'FETCH_DEVICE_STATUS_FAILURE':
          return { ...state, loading: false, error: action.payload };
    
        default:
          return state;
      }
}

export default deviceStatusReducer;