import { GridColDef } from "@mui/x-data-grid";
import DataTable from "../../components/dataTable/DataTable";
import "./users.scss";
import { useEffect, useState } from "react";
import Add from "../../components/add/Add";
import { userRows } from "../../../data";
import { getUsers, updateRole } from "../../../api/api";
// import { useQuery } from "@tanstack/react-query";

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "img",
    headerName: "Avatar",
    width: 100,
    renderCell: (params) => {
      return <img src={params.row.img || "/noavatar.png"} alt="" />;
    },
  },
  {
    field: "username",
    type: "string",
    headerName: "Username",
    width: 150,
  },
  {
    field: "email",
    type: "string",
    headerName: "Email",
    width: 200,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 200,
    type: "string",
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    width: 200,
    type: "string",
  }
];

const Users = () => {
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    const response = await getUsers();
    console.log("USERS :: ", response)
    setUsers(response.map(user => ({...user, email: user.username + "@safaricom.co.ke", updatedAt: user.lastLogin})));
  }

  useEffect(() => {
    fetchUsers();
  },[])

  const handleRole = async (id, role) => {
    const updatedRole =
      role === "user" ? "admin" : role === "superadmin" ? "superadmin" : "user";
    const response = await updateRole(id, updatedRole);
    setUsers(users.map(user => response.data.id === user.id ? {...user, role: response.data.role} : user));
  };

  const actionColumn = {
    field: "role",
    headerName: "Role",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="action">
          <button
            className={`${
              params.row.role === "superadmin"
                ? "bg-red-500"
                : params.row.role === "admin"
                ? "bg-red-300"
                : "bg-green-500"
            } text-white font-bold py-2 px-4 rounded`}
            onClick={() => handleRole(params.row.id, params.row.role)}
          >
            {params.row.role}
          </button>
        </div>
      );
    },
  };

  return (
    <div className="users">
      <div className="info">
        <h1>Users</h1>
        {/* <button onClick={() => setOpen(true)}>Add New User</button> */}
      </div>
      <DataTable
        slug="users"
        columns={[...columns, actionColumn]}
        rows={users}
      />
      {/* TEST THE API */}

      {/* {isLoading ? (
        "Loading..."
      ) : (
        <DataTable slug="users" columns={columns} rows={data} />
      )} */}
      {open && <Add slug="user" columns={columns} setOpen={setOpen} />}
    </div>
  );
};

export default Users;
