import BarChartBox from "../../components/barChartBox/BarChartBox";
import BigChartBox from "../../components/bigChartBox/BigChartBox";
import ChartBox from "../../components/chartBox/ChartBox";
import PieChartBox from "../../components/pieCartBox/PieChartBox";
import TopBox from "../../components/topBox/TopBox";
import {
  barChartBoxRevenue,
  barChartBoxVisit,
  chartBoxConversion,
  chartBoxProduct,
  chartBoxRevenue,
  chartBoxUser,
} from "../../../data";
import "./home.scss";
import { useEffect, useState } from "react";
import { getTotalVisits, getUserPercentage, getUsersCount, getVisitsPercentage } from "../../../api/api";

const Home = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalVisits, setTotalVisits] = useState(0);
  const [visitPercent, setVisitPercent] = useState(0.0);
  const [userPercent, setUserPercent] = useState(0.0);

  const getUsersTotalCount = async () => {
    const res = await getUsersCount();
    setTotalUsers(res)
  }

  const getVisitsTotalCount = async () => {
    const res = await getTotalVisits()
    setTotalVisits(res);
  };

  const userPercentage = async () => {
    const res = await getUserPercentage();
    setUserPercent(res);
  };

  const visitPercentage = async () => {
    const res = await getVisitsPercentage();
    setVisitPercent(res);
  };

  useEffect(() => {
    getUsersTotalCount();
    getVisitsTotalCount();
    userPercentage();
    visitPercentage();
  }, []);

  const updatedChartBoxUser = {
    ...chartBoxUser,
    number: totalUsers,
    percentage: userPercent.toFixed(2),
  };
  const updatedChartBoxConversion = {
    ...chartBoxConversion,
    number: totalVisits,
    percentage: visitPercent.toFixed(2),
  };

  return (
    <div className="home">
      <div className="box box2">
        <ChartBox {...updatedChartBoxUser} />
      </div>
      <div className="box box3">
        <ChartBox {...chartBoxProduct} />
      </div>
      {/* <div className="box box4">
        <PieChartBox />
      </div> */}
      <div className="box box5">
        <ChartBox {...updatedChartBoxConversion} />
      </div>
      {/* <div className="box box6">
        <ChartBox {...chartBoxRevenue} />
      </div> */}
      {/* <div className="box box7">
        <BigChartBox />
      </div> */}
      {/* <div className="box box8">
        <BarChartBox {...barChartBoxVisit} />
      </div>
      <div className="box box9">
        <BarChartBox {...barChartBoxRevenue} />
      </div> */}
      <div className="box box1">
        <TopBox />
      </div>
    </div>
  );
};

export default Home;
