// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(180px, auto);
  grid-auto-flow: dense;
}
@media screen and (max-width: 1200px) {
  .home {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 1024px) {
  .home {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .home {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (max-width: 480px) {
  .home {
    grid-auto-rows: minmax(120px, auto);
  }
}
.home .box {
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #384256;
}
.home .box1 {
  grid-column: span 1;
  grid-row: span 3;
}
.home .box4 {
  grid-column: span 1;
  grid-row: span 3;
}
.home .box7 {
  grid-column: span 2;
  grid-row: span 2;
}
@media screen and (max-width: 768px) {
  .home .box7 {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/dashboard/pages/home/home.scss","webpack://./src/dashboard/styles/responsive.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,SAAA;EACA,qCAAA;EACA,mCAAA;EACA,qBAAA;AAFF;ACgBE;EDnBF;IAQI,qCAAA;EADF;AACF;ACME;EDdF;IAWI,qCAAA;EACF;AACF;ACJE;EDTF;IAcI,qCAAA;EAGF;AACF;ACdE;EDJF;IAiBI,mCAAA;EAKF;AACF;AAHE;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;AAKJ;AAFE;EACE,mBAAA;EACA,gBAAA;AAIJ;AADE;EACE,mBAAA;EACA,gBAAA;AAGJ;AADE;EACE,mBAAA;EACA,gBAAA;AAGJ;AC/BE;ED0BA;IAKI,aAAA;EAIJ;AACF","sourcesContent":["@import \"../../styles/variables.scss\";\n@import \"../../styles/responsive.scss\";\n\n.home {\n  display: grid;\n  gap: 20px;\n  grid-template-columns: repeat(4, 1fr);\n  grid-auto-rows: minmax(180px, auto);\n  grid-auto-flow: dense;\n\n  @include xl{\n    grid-template-columns: repeat(3, 1fr);\n  }\n  @include lg{\n    grid-template-columns: repeat(2, 1fr);\n  }\n  @include md {\n    grid-template-columns: repeat(1, 1fr);\n  }\n  @include sm {\n    grid-auto-rows: minmax(120px, auto);\n  }\n\n  .box {\n    padding: 20px;\n    border-radius: 10px;\n    border: 2px solid $soft-bg;\n  }\n\n  .box1 {\n    grid-column: span 1;\n    grid-row: span 3;\n  }\n\n  .box4 {\n    grid-column: span 1;\n    grid-row: span 3;\n  }\n  .box7 {\n    grid-column: span 2;\n    grid-row: span 2;\n\n    @include md {\n      display: none;\n    }\n  }\n}\n","$sm: 480px;\n$md: 768px;\n$lg: 1024px;\n$xl: 1200px;\n$xxl: 1400px;\n\n@mixin sm {\n  @media screen and (max-width: ($sm)) {\n    @content;\n  }\n}\n@mixin md {\n  @media screen and (max-width: ($md)) {\n    @content;\n  }\n}\n@mixin lg {\n  @media screen and (max-width: ($lg)) {\n    @content;\n  }\n}\n@mixin xl {\n  @media screen and (max-width: ($xl)) {\n    @content;\n  }\n}\n@mixin xxl {\n  @media screen and (max-width: ($xxl)) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
