import React, { useState } from "react";
import { postFeedback } from "../api/api";
import { toast } from "react-toastify";
import styled from "styled-components";

// Styled components for the form
const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogContainer = styled.div`
  background: #fff;
  padding: 24px;
  width: 50%;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

const DialogTitle = styled.h2`
  margin: 0 0 16px 0;
  font-size: 24px;
`;

const DialogParagraph = styled.p`
  font-size: 12px;
  padding: 2px;
  margin: 2px;
`;

const DialogContent = styled.div`
  margin-bottom: 16px;
`;

const DialogBorder = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 30px;
`;

const DialogActions = styled.div`
  display: flex;
  justify-content: center;
`;

// const InputField = styled.input`
//   width: 100%;
//   padding: 12px;
//   margin-bottom: 16px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   font-size: 16px;
// `;

// const TextAreaField = styled.textarea`
//   width: 100%;
//   padding: 12px;
//   margin-bottom: 16px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   font-size: 16px;
// `;

const Button = styled.button`
  padding: 8px 16px;
  margin-left: 8px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.primary ? "#007BFF" : "#ccc")};
  color: ${(props) => (props.primary ? "#fff" : "#000")};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0056b3" : "#bbb")};
  }
`;

const ErrorText = styled.p`
  color: red;
  margin: 8px 0;
`;

// Main component
const ResetPasswordModal = ({ passDialog, handlePassDialog }) => {
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState(null);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!password && !confirmPass) {
      setError("Please enter password.");
      return;
    }

    if (password !== confirmPass) {
      setError("Passwords don't match");
      return;
    }

    if (password !== confirmPass) {
      setError(
        "minimum length of 14 characters, comprising a combination of alphanumeric characters and symbols"
      );
      return;
    }

    try {
      //   await postFeedback({ password, confirmPass });
      toast.success("Password updated successfully");
      setPassword("");
      setConfirmPass("");
      setError(null);
      handlePassDialog(!passDialog);
    } catch (err) {
      toast.error("Password not updated. Please try again.");
    }
  };

  return (
    <DialogOverlay>
      <DialogContainer>
        <DialogTitle>Update/Reset your AD password</DialogTitle>
        <DialogBorder />
        <DialogParagraph>
          NB:: Create a strong password with minimum length of 14 characters,
          comprising a combination of alphanumeric characters and symbols.
        </DialogParagraph>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            {error && <ErrorText>{error}</ErrorText>}
            <input
              type="password"
              placeholder="Enter password"
              className="border-2 border-green-500 m-2 rounded-full px-2 py-3 w-[80%]"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Confirm password"
              className="border-2 border-green-500 m-2 rounded-full px-2 py-3 w-[80%]"
              value={confirmPass}
              onChange={(e) => setConfirmPass(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handlePassDialog(!passDialog);
                setError(null);
              }}
            >
              Cancel
            </Button>
            <Button primary type="submit">
              Reset Password
            </Button>
          </DialogActions>
        </form>
      </DialogContainer>
    </DialogOverlay>
  );
};

export default ResetPasswordModal;
