import { Link } from "react-router-dom";
import { SidebarItem } from "./Sidebar";
import * as Icons from "lucide-react";
import { useAppContext } from "../contexts/AppContext";
import { useEffect, useState } from "react";
import { getRole } from "../api/api";

const SideNav = ({ menu }) => {
  const [userRole, setUserRole] = useState("user");
  const { appState } = useAppContext();

      const detectPlatform = () => {
        const platform = navigator.userAgentData.platform;
        if (platform.includes("Windows")) {
          return "Windows";
        } else {
          return "";
        }
      };
  
  useEffect(() => {
    const getUserRole = async (username) => {
      const response = await getRole(username);
      setUserRole(response && response.data ? response.data.role : "user");
    };
    getUserRole(appState.email.split("@")[0]);
  }, []);
  
  return (
    <div className="menu">
      {menu.map((listItem) => {
        const IconComponent = Icons[listItem.icon] || Icons.FormInputIcon; // Default to FormInputIcon if not found

        return (
          <>
            <Link to={listItem.url} className="listItem" key={listItem.id}>
              <SidebarItem
                icon={<IconComponent size={18} />}
                text={
                  <p className="font-futura-bold text-lg bold">
                    {listItem.title}
                  </p>
                }
              />
            </Link>
          </>
        );
      })}
      {(userRole === "superadmin" || userRole === "admin") && (
        <>
          <Link to={"upload"}>
            <SidebarItem
              icon={<Icons.UploadCloudIcon size={18} />}
              text={<p className="font-futura-bold text-lg bold">Upload FAQ</p>}
            />
          </Link>
          <Link to={"connection-links"}>
            <SidebarItem
              icon={<Icons.LinkIcon size={18} />}
              text={
                <p className="font-futura-bold text-lg bold">
                  Enabler Links
                </p>
              }
            />
          </Link>
          <SidebarItem
            icon={<Icons.Database size={18} />}
            text={
              <p className="font-futura-bold text-lg bold">EUDA db</p>
            }
            onClick={() => window.open("https://eudadb-euda.apps.devocp.safaricom.net", "_blank")}
          />
        </>
      )}
      {detectPlatform() === "Windows" && (
        <SidebarItem
          icon={<Icons.BoxesIcon size={18} />}
          text={
            <p className="font-futura-bold text-lg bold">Software Center</p>
          }
          onClick={() => (window.location.href = "softwarecenter: SoftwareID")}
        />
      )}
    </div>
  );
};

export default SideNav;
