import React from "react";
import styled from "styled-components";

// Styled Components
const DetailContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: white;
  padding: 8px;
  margin: 4px 0;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  padding: 4px;
  margin: 4px;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  padding: 4px;
  font-family: "Futura, sans-serif";
  font-size: 14px;
  font-weight: bold;
`;

const Description = styled.div`
  padding: 4px;
  font-family: "Futura, sans-serif";
  font-size: 12px;
  font-weight: bold;
  color: #6b6b6b;
`;

export default function Details({ title, description, icon }) {
  return (
    <DetailContainer data-testid="device-status">
      <IconWrapper>
        <Icon src={icon} alt="ICON" />
      </IconWrapper>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </DetailContainer>
  );
}
