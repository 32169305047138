import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import { DeviceStatusContextProvider } from "./contexts/DeviceStatusContext";
import { useAppContext } from "./contexts/AppContext";
import { useEffect } from "react";
import { SidebarContextProvider } from "./contexts/SideBarContext";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import Home from "./dashboard/pages/home/Home";
import Users from "./dashboard/pages/users/Users";
import Products from "./dashboard/pages/products/Products";
import User from "./dashboard/pages/user/User";
import Product from "./dashboard/pages/product/Product";
import Chatbot from "./components/bot/ChatBot";
import DeviceStatus from "./components/device-status/DeviceStatus";
import Modal from "./components/Modal";
import Webinar from "./components/Webinar";
import AccountInfo from "./components/AccountInfo";
import ConnectionLinksComponent from "./components/ConnectionLinksComponent";
import FaqUploadForm from "./components/FaqUploadForm";
import Category from "./dashboard/pages/categories/Category";
import Faq from "./dashboard/pages/faqs/Faq";

function App() {
  const { appState, updateState } = useAppContext();
  useEffect(() => {
    updateState(appState);
  }, [appState, updateState]);

  console.log("CHECK PLATFORM :: {} ", appState);

  const CustomerPage = () => (
    <DeviceStatusContextProvider>
      <SidebarContextProvider>
        {Object.getOwnPropertyNames(appState).length > 0 ? (
          <HomePage />
        ) : (
          <LoginPage />
        )}
      </SidebarContextProvider>
    </DeviceStatusContextProvider>
  );

  console.log("App State: " + Object.getOwnPropertyNames(appState));

  const isAuthenticated =
    Object.getOwnPropertyNames(appState).length > 0 &&
      (appState.email.toLowerCase() ===
        "akkiprono@safaricom.co.ke".toLowerCase() ||
        appState.email.toLowerCase() ===
          "tkipngeno@safaricom.co.ke".toLowerCase() ||
        appState.email.toLowerCase() ===
          "ymusyoka@safaricom.co.ke".toLowerCase() ||
        appState.email.toLowerCase() ===
          "pmukera@safaricom.co.ke".toLowerCase());

  const ProtectedDashboard = () => {
    if (isAuthenticated) {
      console.log("Is authenticated: " + isAuthenticated)
      return <Dashboard />;
    } else {
      return <Navigate to="/" />;
    }
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <CustomerPage />,
      children: [
        {
          path: "/chat",
          element: <Chatbot />,
        },
        {
          path: "/",
          element: <AccountInfo />,
        },
        {
          path: "/device-status",
          element: <DeviceStatus />,
        },
        {
          path: "/catalog",
          element: <Modal />,
        },
        {
          path: "/webinar",
          element: <Webinar />,
        },
        {
          path: "/upload",
          element: <FaqUploadForm />,
        },
        {
          path: "/connection-links",
          element: <ConnectionLinksComponent />,
        },
      ],
    },
    {
      path: "/dashboard",
      element: <ProtectedDashboard />,
      children: [
        {
          path: "/dashboard",
          element: <Home />,
        },
        {
          path: "/dashboard/users",
          element: <Users />,
        },
        {
          path: "/dashboard/feedbacks",
          element: <Products />,
        },
        {
          path: "/dashboard/categories",
          element: <Category />,
        },
        {
          path: "/dashboard/faqs",
          element: <Faq />,
        },
        {
          path: "/dashboard/users/:id",
          element: <User />,
        },
        {
          path: "/dashboard/products/:id",
          element: <Product />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;