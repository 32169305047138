// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dataTable .dataGrid {
  background: white;
  padding: 20px;
}
.dataTable .dataGrid .MuiDataGrid-toolbarContainer {
  flex-direction: row-reverse;
}
.dataTable .dataGrid img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
.dataTable .dataGrid .action {
  display: flex;
  gap: 15px;
}
.dataTable .dataGrid .action img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/components/dataTable/dataTable.scss"],"names":[],"mappings":"AAEE;EACE,iBAAA;EACA,aAAA;AADJ;AAGI;EACE,2BAAA;AADN;AAII;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AAFN;AAKI;EACE,aAAA;EACA,SAAA;AAHN;AAKM;EACE,WAAA;EACA,YAAA;EACA,eAAA;AAHR","sourcesContent":[".dataTable{\n\n  .dataGrid{\n    background: white;\n    padding: 20px;\n\n    .MuiDataGrid-toolbarContainer{\n      flex-direction: row-reverse;\n    }\n\n    img{\n      width: 32px;\n      height: 32px;\n      border-radius: 50%;\n      object-fit: cover;\n    }\n\n    .action{\n      display: flex;\n      gap: 15px;\n\n      img{\n        width: 20px;\n        height: 20px;\n        cursor: pointer;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
