// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
}
.footer span:first-child {
  font-weight: bold;
}
.footer span:last-child {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/components/footer/footer.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;AACF;AAGI;EACE,iBAAA;AADN;AAII;EACE,eAAA;AAFN","sourcesContent":[".footer{\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 30px;\n\n  span{\n\n    &:first-child{\n      font-weight: bold;\n    }\n\n    &:last-child{\n      font-size: 14px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
