import React from "react";
export default function Webinar() {
  return (
    <div className="w-full">
          <iframe src="https://outlook.office.com/book/SAFARICOMWEBINARS2@safaricomO365.onmicrosoft.com/?login_hint"
              width="100%"
              height="100%"
          ></iframe>
    </div>
  );
}
