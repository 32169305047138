import Header from "../components/Header";
import OtpForm from "../components/OtpForm";

const LoginPage = () => {
  return (
    <>
      {/* <Header /> */}
      <OtpForm />
      {/* <div className="mb-1 border-2 border-gray-300" />
      <div className="container flex flex-col bg-gray-200">
        <div className="container flex">
          <div
            style={{ height: "85vh" }}
            className="flex flex-1 bg-green-300 justify-center items-center p-4 bg-white rounded-tl-md"
          >
            <OtpForm />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default LoginPage;
