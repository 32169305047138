import React from "react";
// Display the bot header
export default function Header() {
  return (
    <header
      data-testid="header"
      className="container mx-auto max-w-screen-2xl flex bg-white p-4 items-center"
    >
      <img
        src={"/images/Safaricom-Logo.wine.svg"}
        className={`overflow-hidden transition-all w-60`}
        alt="LOGO"
      />
      <h2 className="font-futura-bold text-24 font-bold">
        Workspace Virtual Assist
      </h2>
    </header>
  );
}
