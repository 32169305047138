import React, { useEffect } from "react";
import styled from "styled-components";
import Info from "./Info";
import Details from "./Details";
import { useDeviceStatusContext } from "../../contexts/DeviceStatusContext";
import LoadingComponent from "../LoadingComponent";
import { useAppContext } from "../../contexts/AppContext";

export const formattedDeviceStatus = (item) => {
  return [
    {
      title: "Device Model",
      description: item[0],
      icon: "/images/Vector.png",
    },
    {
      title: "Serial Number",
      description: item[3],
      icon: "/images/serial.png",
    },
    {
      title: "Replacement Date",
      description: item[7].substring(0, item[7].indexOf(".")),
      icon: "/images/Calandar.png",
    },
    {
      title: "Asset Number",
      description: item[4],
      icon: "/images/assest.png",
    },
    {
      title: "Status",
      description: item[5],
      icon: "/images/status.png",
    },
  ];
};

// Styled Components
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 100vh;
  overflow: auto;
  background: #f4f4f9;
`;

const DeviceCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 8px;
  padding: 16px;
  width: 500px;
`;

const DeviceTitle = styled.h3`
  margin: 8px 0;
  padding: 8px;
  font-family: "Futura, sans-serif";
  font-size: 16px;
  font-weight: bold;
`;

const NoDevicesMessage = styled.h3`
  text-align: center;
  margin-top: 20px;
  font-family: "Futura, sans-serif";
`;

export default function DeviceStatus() {
  const { state, fetchDeviceStatus } = useDeviceStatusContext();
  const { appState } = useAppContext();

  useEffect(() => {
    fetchDeviceStatus(appState.email.split("@")[0]);
  }, [appState.email]);

  return (
    <div>
      <Info />

      <Container data-testid="d-status">
        {state.loading && <LoadingComponent />}
        {!state.loading && state.status.length === 0 && (
          <NoDevicesMessage>No device(s) available</NoDevicesMessage>
        )}
        {state.status.map((device, index) => (
            <DeviceCard key={index}>
              {state.status.length > 1 && (
                <DeviceTitle>{device[0]}</DeviceTitle>
              )}
              <div>
                {formattedDeviceStatus(device).map((item, itemIndex) => (
                  <Details
                    key={itemIndex}
                    title={item.title}
                    description={item.description}
                    icon={item.icon}
                  />
                ))}
              </div>
            </DeviceCard>
        ))}
      </Container>
    </div>
  );
}
