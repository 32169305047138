export const initialState = {
    user: {},
    loading: false,
    error: null,
    authenticated: false
}

const authReducer = (state, action) => {
    switch(action.type){
        case "AUTH_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
                authenticated: false
            }
        case "AUTH_USERNAME_SUCCESS":
            return {
                ...state,
                user: action.payload,
                loading: false,
                error: null,
                authenticated: false
            }
        case "AUTH_OTP_SUCCESS":
                return {
                ...state,
                user: action.payload,
                loading: false,
                error: null,
                authenticated: true
            }
        case "AUTH_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.payload,
                authenticated: false
            }
        default:
            return state;
    }
}

export default authReducer;