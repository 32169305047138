import "./add.scss";
import { useState } from "react";
import { createCategory } from "../../../api/api";
import { useAppContext } from "../../../contexts/AppContext";
import { toast } from "react-toastify";

const Add = (props) => {
  const [category, setCategory] = useState("");
  const {appState} = useAppContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await createCategory(appState.email.split("@")[0], category);
    toast.success("Category created successfully");
    props.setCategories([...props.categories, { ...response, id: props.categories.length + 1 }]);
    props.setOpen(false);
  };
  return (
    <div className="add">
      <div className="modal">
        <span className="close" onClick={() => props.setOpen(false)}>
          X
        </span>
        <h1>Add new {props.slug}</h1>
        <form onSubmit={handleSubmit}>
          {props.columns
            .filter(
              (item) =>
                item.field !== "id" &&
                item.field !== "img" &&
                item.field !== "createdAt" &&
                item.field !== "createdBy"
            )
            .map((column) => (
              <div className="item">
                <label>{column.headerName}</label>
                <input
                  type={column.type}
                  placeholder={column.field}
                  style={{ width: "100%" }}
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
              </div>
            ))}
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
};

export default Add;