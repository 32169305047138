// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bigChartBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 1400px) {
  .bigChartBox h1 {
    font-size: 24px;
  }
}
.bigChartBox .chart {
  width: 100%;
  height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/components/bigChartBox/bigChartBox.scss","webpack://./src/dashboard/styles/responsive.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AADF;ACqBE;EDlBA;IAEI,eAAA;EADJ;AACF;AAGE;EACE,WAAA;EACA,aAAA;AADJ","sourcesContent":["@import \"../../styles/responsive.scss\";\n\n.bigChartBox{\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n\n  h1{\n    @include xxl{\n      font-size: 24px;\n    }\n  }\n  .chart{\n    width: 100%;\n    height: 300px;\n  }\n}","$sm: 480px;\n$md: 768px;\n$lg: 1024px;\n$xl: 1200px;\n$xxl: 1400px;\n\n@mixin sm {\n  @media screen and (max-width: ($sm)) {\n    @content;\n  }\n}\n@mixin md {\n  @media screen and (max-width: ($md)) {\n    @content;\n  }\n}\n@mixin lg {\n  @media screen and (max-width: ($lg)) {\n    @content;\n  }\n}\n@mixin xl {\n  @media screen and (max-width: ($xl)) {\n    @content;\n  }\n}\n@mixin xxl {\n  @media screen and (max-width: ($xxl)) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
