// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

.main {
  font-family: "Inter", sans-serif;
  background-color: #2a3447;
  color: white;
}

.container {
  display: flex;
}

.menuContainer {
  width: 250px;
  padding: 5px 20px;
  border-right: 2px solid #384256;
}
@media screen and (max-width: 1024px) {
  .menuContainer {
    width: max-content;
  }
}

.contentContainer {
  padding: 5px 20px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/styles/global.scss","webpack://./src/dashboard/styles/variables.scss","webpack://./src/dashboard/styles/responsive.scss"],"names":[],"mappings":"AAGA;EACE,SAAA;EACA,UAAA;EACA,sBAAA;AAFF;;AAKA;EACE,qBAAA;EACA,cAAA;AAFF;;AAKA;EACE,gCAAA;EACA,yBCfQ;EDgBR,YCZW;ADUb;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE,YAAA;EACA,iBAAA;EACA,+BAAA;AAFF;AERE;EFOF;IAMI,kBAAA;EADF;AACF;;AAIA;EACE,iBAAA;EACA,WAAA;AADF","sourcesContent":["@import \"./variables.scss\";\n@import \"./responsive.scss\";\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\na {\n  text-decoration: none;\n  color: inherit;\n}\n\n.main {\n  font-family: \"Inter\", sans-serif;\n  background-color: $main-bg;\n  color: $main-color;\n}\n\n.container {\n  display: flex;\n}\n\n.menuContainer {\n  width: 250px;\n  padding: 5px 20px;\n  border-right: 2px solid $soft-bg;\n\n  @include lg{\n    width: max-content;\n  }\n}\n\n.contentContainer {\n  padding: 5px 20px;\n  width: 100%;\n}\n","// BG\n$main-bg: #2a3447;\n$soft-bg: #384256;\n$dark-bg: #222b3c;\n//TEXT\n$main-color: white;\n$soft-color: #ddd;\n$dark-color: #2a3447;","$sm: 480px;\n$md: 768px;\n$lg: 1024px;\n$xl: 1200px;\n$xxl: 1400px;\n\n@mixin sm {\n  @media screen and (max-width: ($sm)) {\n    @content;\n  }\n}\n@mixin md {\n  @media screen and (max-width: ($md)) {\n    @content;\n  }\n}\n@mixin lg {\n  @media screen and (max-width: ($lg)) {\n    @content;\n  }\n}\n@mixin xl {\n  @media screen and (max-width: ($xl)) {\n    @content;\n  }\n}\n@mixin xxl {\n  @media screen and (max-width: ($xxl)) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
