// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category .info {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.category .info button {
  padding: 5px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/pages/categories/category.scss"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AADJ;AAGI;EACE,YAAA;EACA,eAAA;AADN","sourcesContent":[".category{\n\n  .info{\n    display: flex;\n    align-items: center;\n    gap: 20px;\n    margin-bottom: 20px;\n\n    button{\n      padding: 5px;\n      cursor: pointer;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
